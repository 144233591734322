import { useGetVehicleTypes } from 'api-hooks/voyage/query';
import { VehicleIcon } from 'assets/images/svg';
import IconBox from 'components/commons/icon-box';
import Input from 'components/elements/field';
import { StepperSelectOption } from 'components/elements/stepper-select';
import useTranslation from 'next-translate/useTranslation';
// import { useRouter } from 'next/router';
import * as React from 'react';
// import { useFormContext } from 'react-hook-form';
import { Oval } from 'react-loader-spinner';
import color from 'styles/color';

interface Props {
  name: string;
  required?: boolean;
  disabled?: boolean;
  options?: StepperSelectOption[];
  isLoading?: boolean;
  isFetching?: boolean;
  onAfterChange?: (params: StepperSelectOption) => void;
}

export default function VehicleTypesSelectInput(props: Props) {
  const { options, isFetching, isLoading, ...rest } = props;
  const { t } = useTranslation();

  const [isInitiate, setIsInitiate] = React.useState(false);
  const [optionsApi, setOptionsApi] = React.useState<StepperSelectOption[]>([]);

  const {
    data,
    isLoading: vehicleLoading,
    isFetching: vehicleFetching,
  } = useGetVehicleTypes({
    enabled: !options,
  });

  React.useEffect(() => {
    if (!isInitiate && data?.data) {
      const _options = data.data.map((item) => ({
        value: item.id,
        label: item.name,
        caption: item.description,
      }));
      setOptionsApi(_options);
      setIsInitiate(true);
    }
  }, [data, isInitiate]);

  if (isLoading || isFetching || vehicleFetching || vehicleLoading) {
    return (
      <Input
        {...rest}
        type="text"
        value=""
        name=""
        disabled
        label={t('common:vehicle_type')}
        placeholder={t('common:select_vehicle_type')}
        onRenderLeftComponent={
          <IconBox css={{ pl: 16 }}>
            <Oval
              color={color.primary}
              secondaryColor={color.primary5}
              width={24}
              height={24}
            />
          </IconBox>
        }
      />
    );
  }
  return (
    <Input
      {...rest}
      type="dropdown-select"
      label={t('common:vehicle_type')}
      placeholder={t('common:select_vehicle_type')}
      options={options ?? optionsApi}
      onAfterChange={props.onAfterChange}
      onRenderLeftComponent={
        <IconBox css={{ ml: 16 }}>
          <VehicleIcon size={24} />
        </IconBox>
      }
    />
  );
}
