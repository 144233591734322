import { ShippingTypeIcon } from 'assets/images/svg';
import IconBox from 'components/commons/icon-box';
import Input from 'components/elements/field';
import useTranslation from 'next-translate/useTranslation';

interface Props {
  name: string;
  required: boolean;
  onChange: () => void;
}

export default function CrossingTypeSelectInput(props: Props) {
  const { t } = useTranslation();
  const { ...rest } = props;
  return (
    <Input
      type="select"
      {...rest}
      label={t('common:type_of_crossing')}
      placeholder={t('common:select_type_of_crossing')}
      options={[
        { value: 0, label: t('common:passenger') },
        { value: 1, label: t('common:vehicle_without_passenger') },
        { value: 2, label: t('common:passenger_and_vehicle') },
      ]}
      onRenderLeft={
        <IconBox css={{ ml: 16 }}>
          <ShippingTypeIcon size={24} />
        </IconBox>
      }
    />
  );
}
