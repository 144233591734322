import Text from 'components/commons/text';
import { css, styled } from 'styles';
import typography from 'styles/typography';

export const Section = styled('section', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export const Image = styled('img', {
  objectFit: 'cover',
  height: '100%',
  width: '100%',
  objectPosition: '50% 50%',
  borderRadius: 8,
});

export const ImagePopup = styled('img', {
  borderRadius: 8,
  objectFit: 'contain',
  alignSelf: 'center',
  maxHeight: '60vh',
});

export const ImageItemContainer = styled('div', {
  width: 250,
  px: 8,
  '@md': {
    width: 400,
  },
});

export const styles = {
  breakPointsCss: {
    width: '100%',
    px: 16,
    '@sm': {
      maxWidth: 576,
    },
    '@md': {
      maxWidth: 768,
    },
    '@lg': {
      maxWidth: 992,
    },
    '@xl': {
      maxWidth: 1200,
    },
  },
  halfContent: css({
    width: '100%',
    '@md': {
      width: '47%',
    },
  }),
  tabContents: css({
    padding: 8,
  }),
  topFieldInput: css({
    width: '100%',
    '@md': {
      width: '32%',
    },
  }),
};
export const LabelText = styled(Text, {
  minWidth: 150,
  color: '$primary',
  ...typography.headline6,
  '@md': {
    minWidth: 200,
    ...typography.headline5,
  },
});
export const InfoText = styled(Text, {
  ...typography.body2,
  textAlign: 'justify',
  '@md': { ...typography.body1, maxWidth: 400 },
});
