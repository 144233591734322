import { PassengerSvg } from 'assets/images/svg';
import IconBox from 'components/commons/icon-box';
import Input from 'components/elements/field';
import { StepperSelectOption } from 'components/elements/stepper-select';
import useTranslation from 'next-translate/useTranslation';
import * as React from 'react';
import { Oval } from 'react-loader-spinner';
import color from 'styles/color';
interface Props {
  name: string;
  required?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  isFetching?: boolean;
  options: StepperSelectOption[];
  hint?: string;
}

export default function PassengerTypesSelectInput(props: Props) {
  const { t } = useTranslation();
  const {
    isLoading,
    isFetching,
    options,
    hint = t('common:min_one_adult'),
    ...rest
  } = props;

  if (isLoading || isFetching) {
    return (
      <Input
        {...rest}
        type="text"
        value=""
        name=""
        disabled
        label={t('common:total_passenger')}
        placeholder={t('common:select_number_of_passengers')}
        hint={hint}
        onRenderLeftComponent={
          <IconBox css={{ pl: 16 }}>
            <Oval
              color={color.primary}
              secondaryColor={color.primary5}
              width={24}
              height={24}
            />
          </IconBox>
        }
      />
    );
  }
  return (
    <Input
      {...rest}
      type="stepper-select"
      label={t('common:total_passenger')}
      placeholder={t('common:select_number_of_passengers')}
      hint={hint}
      options={options}
      onRenderLeftComponent={
        <IconBox css={{ ml: 16 }}>
          <PassengerSvg />
        </IconBox>
      }
    />
  );
}
